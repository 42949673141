import * as S from './styles'

const Debug: React.FC<DebugProps> = ({ values, isFixed = true, ...rest }) => {
  return (
    <S.Container isFixed={isFixed} {...rest}>
      {values.map((item, index) => (
        <small key={index}>
          <strong>{item.label}:</strong> {`${item.value}`}
        </small>
      ))}
    </S.Container>
  )
}

export default Debug
