import styled, { css } from 'styled-components'

const containerModifiers = {
  isClosed: () => css`
    button {
      transform: translateX(calc(100% + 0.5rem));
    }

    #main-logo-closed-menu {
      max-width: 160px;
      border-radius: 0.25rem;
    }
  `,
  isMobile: () => css`
    button {
      transform: translate(3.5rem, -2px);
    }

    #main-logo-closed-menu {
      max-width: 0;
    }
  `
}

export const Container = styled.div`
  ${({ sidebarIsOpened, isMobile, bgHeader }) => css`
    --bg-color: ${bgHeader};

    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 1.5rem;
    background: var(--bg-color);
    height: var(--mainHeaderHeight);

    &:hover {
      text-decoration: none;
    }

    &:focus {
      outline: 0;
    }

    a {
      max-width: 160px;

      > svg {
        min-width: 160px;
      }
    }

    button {
      position: absolute;
      right: 0;

      z-index: 8888;
      transition: ease-in-out 0.3s all;
      min-width: 32px;

      font-size: 1.5rem;

      svg {
        width: 1.5rem;
      }
    }

    #main-logo-closed-menu {
      overflow: hidden;
      position: absolute;
      max-width: 0;
      z-index: 9999;
      transition: ease-in-out 0.3s all;
      top: 50%;
      right: 0;
      transform: translate(calc(100% + 3rem), -50%);
      padding: 0rem;
    }

    ${!sidebarIsOpened && containerModifiers.isClosed()}
    ${isMobile && containerModifiers.isMobile()}
  `}
`
