import styled, { DefaultTheme, css } from 'styled-components'

export const Container = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &[data-layout='inline'] {
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }

    &[data-layout='grid'] {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  `}
`

/**
 * Item
 */

type ItemProps = {
  size?: RadioItemSizes
  hasActiveChildren?: boolean
  isSelected?: boolean
}

const itemModifiers = {
  hasActiveChildren: () => css``,
  isSelected: () => css`
    box-shadow: 0px 4px 8px rgba(24, 21, 60, 0.08);
  `,
  activeChildren: () => css`
    label {
      margin-bottom: 0;
    }
  `,
  size: {
    // Deve virar um template
    tiny: () => css`
      --paddingY: 0rem;
      --padding: 0 0 0 1.25rem;
      display: inline-flex;
      box-shadow: none !important;

      label {
        background: transparent !important;
        border: none !important;

        .label {
          font-size: 0.875rem;
          font-weight: normal;
        }

        &:before,
        &:after {
          left: 0.4rem;
        }
      }
    `,
    small: (theme: DefaultTheme) => css`
      --paddingY: ${theme.spacings.xxxsmall};
      --paddingStart: ${theme.spacings.small};
      --paddingEnd: 3rem;
      --padding: var(--paddingY) var(--paddingStart) var(--paddingY)
        var(--paddingEnd);
    `,
    medium: (theme: DefaultTheme) => css`
      --paddingY: ${theme.spacings.medium};
      --paddingStart: ${theme.spacings.small};
      --paddingEnd: 3rem;
      --padding: var(--paddingY) var(--paddingStart) var(--paddingY)
        var(--paddingEnd);
    `
  }
}

export const Item = styled.div<ItemProps>`
  ${({ theme, hasActiveChildren, isSelected, size = 'medium' }) => css`
    --borderColor: var(--neutralHighLight);
    --backgroundColor: ${theme.colors.neutral.high.lightest};

    --bulletRadius: ${theme.colors.primary};
    --backgroundBullet: ${theme.colors.white};

    --labelColor: var(--neutralLowDark);

    /* Controlando layers para o activeChildren  */
    position: relative;
    z-index: 2;
    box-shadow: 0;
    transition: all ${theme.transition.default};

    &:hover:not(.disabled) {
      box-shadow: 0px 4px 8px rgba(24, 21, 60, 0.08);
      --borderColor: var(--hover);
    }

    &:active {
      box-shadow: 0px 2px 4px rgba(24, 21, 60, 0.08);
    }

    &.disabled {
      --bulletRadius: var(--neutralLowLightest);
      --backgroundBullet: var(--neutralHighLight);
      --labelColor: var(--neutralLowLight);

      cursor: not-allowed;

      * {
        cursor: not-allowed;
      }

      label {
        &:hover {
          color: inherit;
        }
      }
    }

    &.hide,
    input[type='radio'] {
      display: none;
    }

    &.selected {
    }

    /* Input */
    input[type='radio']:checked,
    &.selected input[type='radio'] {
      /* The .selected class is used in the radio list */
      + label {
        --borderColor: ${theme.colors.primary};

        border-color: var(--borderColor);
        background-color: var(--backgroundColor);
        color: ${theme.colors.primary};

        &:before {
          border-color: var(--borderColor);
        }

        &:after {
          width: ${theme.spacings.xxsmall};
          height: ${theme.spacings.xxsmall};
        }

        strong {
          color: ${theme.colors.primary};
        }
        /* span {
          color: ${theme.colors.dark};
        } */
      }
    }

    /* Label */
    label {
      /* reset */
      margin-bottom: 0;
      /* reset */

      --bulletWrapperRadius: ${theme.spacings.large};

      position: relative;

      display: flex;
      flex: auto;
      flex-wrap: wrap;

      border-radius: ${theme.border.radius.medium};
      padding: var(--padding);
      height: 100%;
      min-height: var(--bulletWrapperRadius);

      border: 1px solid var(--borderColor);
      background-color: ${theme.colors.light};

      transition: all ${theme.transition.fast};
      cursor: pointer;

      &:hover {
        background-color: ${theme.colors.neutral.high.lightest};
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: calc(var(--paddingY) + 0.125rem);
        left: 1.6rem;
        transform: translate(-50%, 0);
        border-radius: ${theme.border.radius.rounded};
        transition:
          all ${theme.transition.fast},
          height ${theme.transition.fast};
      }

      &:after {
        // 4px = 2px border + 2px padding
        top: calc(var(--paddingY) + 0.125rem + 4px);
      }

      &:before {
        width: var(--bulletWrapperRadius);
        height: var(--bulletWrapperRadius);
        border: 2px solid var(--bulletRadius);

        background-color: var(--backgroundBullet);
      }

      &:after {
        width: 0;
        height: 0;

        background: ${theme.colors.success};
      }

      aside {
        &.radio__tooltip {
          display: flex;
          align-items: center;
          height: 20px;
        }
      }
    }

    ${hasActiveChildren && isSelected && itemModifiers.activeChildren()}
    ${isSelected && itemModifiers.isSelected()}
    ${itemModifiers.size[size](theme)}
  `}
`

export const ActiveChildren = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-top: -${theme.spacings.medium};
    margin-bottom: 0.5rem; // Retornando MB default do label
    /* z-index: 3; */ // teste: tirando pois estava dando problema na criação de campanha 26/02/2025

    border-radius: ${theme.border.radius.medium};
    padding: 2rem ${theme.spacings.medium} ${theme.spacings.medium}
      ${theme.spacings.medium};

    border: 1px solid ${theme.colors.tertiary};
    background-color: ${theme.colors.white};

    transition: all ${theme.transition.fast};
  `}
`
