import { FiCheckCircle } from 'react-icons/fi'
import { IoAlertOutline } from 'react-icons/io5'

import { IconCircle } from '../Icon/Circle'

const themes: BadgeIsValidTheme = {
  valid: {
    icon: FiCheckCircle,
    template: 'successFill'
  },
  invalid: {
    icon: IoAlertOutline,
    template: 'dangerFill'
  }
}

const BadgeIsValid = ({ isValid }: BadgeIsValidProps) => (
  <IconCircle {...(isValid ? themes.valid : themes.invalid)} />
)

export default BadgeIsValid
