import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useCallback
} from 'react'

import { useField } from '@unform/core'

import Tooltip from '~/components/Tooltip'

import * as S from './styles'

const RadioInput = ({
  name,
  options = [],
  isGrid,
  isInline,
  layout = 'default',
  itemSize = 'small',
  onChange,
  initialValue,
  ...rest
}: RadioInputProps) => {
  const inputRefs = useRef<HTMLInputElement[]>([])
  const { fieldName, registerField, defaultValue } = useField(name)

  const [selectedItemValue, setSelectedItemValue] = useState<string | boolean>()

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      //@ts-expect-error erro do unform - tratar assim que possível
      getValue: (refs: HTMLInputElement[]) => {
        const checkedInput = refs.find(ref => ref?.checked)

        return checkedInput?.value
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach(ref => {
          ref.checked = false
        })
      },
      setValue: (refs: HTMLInputElement[], values: string[]) => {
        refs.forEach(ref => {
          if (values?.includes(ref.id)) {
            ref.checked = true
          }
        })
      }
    })
  }, [defaultValue, fieldName, registerField])

  useLayoutEffect(() => {
    setSelectedItemValue(defaultValue || initialValue)
  }, [defaultValue, initialValue])

  const handleChange = useCallback(
    (data: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(data)

      const { value } = data.target

      setSelectedItemValue(value)
    },
    [onChange]
  )

  return (
    <S.Container
      className="radioListWrapper"
      isInline={isInline}
      isGrid={isGrid}
      data-layout={layout}
    >
      {options.map((option, index) => (
        <React.Fragment key={`${name}${option.value}`}>
          <S.Item
            hasActiveChildren={!!option?.activeChildren}
            isSelected={selectedItemValue === option.value}
            size={itemSize}
            className={`radioFieldWrapper ${
              option?.disabled ? 'disabled' : ''
            }`}
          >
            <input
              defaultChecked={
                defaultValue == option.value ||
                (!defaultValue && initialValue == option.value)
              }
              ref={ref => {
                inputRefs.current[index] = ref as HTMLInputElement
              }}
              //@ts-expect-error erro do unform - tratar assim que possível
              value={option.value}
              type="radio"
              id={option?.id || `${name}${option.value}`}
              name={name}
              disabled={option?.disabled}
              onChange={handleChange}
              {...rest}
            />

            <label htmlFor={option.id || `${name}${option.value}`}>
              <div className="label_content flex-fill">
                <section
                  className="d-flex justify-content-between w-100"
                  style={{ color: 'var(--labelColor)' }}
                >
                  {option.icon && (
                    <span className="d-inline-flex align-items-center me-1 fw-bold fs-5">
                      {option.icon}
                    </span>
                  )}

                  {option.label && (
                    <div
                      className="radio--label-label d-flex flex-fill fw-semibold"
                      style={{ paddingTop: '1px' }}
                    >
                      {option.label}
                    </div>
                  )}

                  {option?.sideRight && <aside>{option.sideRight}</aside>}
                  {option?.tooltipText && (
                    <aside className="radio__tooltip">
                      <Tooltip text={option?.tooltipText} />
                    </aside>
                  )}
                </section>

                {option.description && (
                  <section className="radio--label--description flex-fill w-100 lh-sm fs-6">
                    {option.description}
                  </section>
                )}
              </div>
            </label>
          </S.Item>

          {option?.activeChildren && selectedItemValue === option.value && (
            <S.ActiveChildren>{option.activeChildren}</S.ActiveChildren>
          )}
        </React.Fragment>
      ))}
    </S.Container>
  )
}

export default RadioInput
