import { forwardRef } from 'react'

import { FormHandles, FormProps } from '@unform/core'
import { Form as Unform } from '@unform/web'

const Form = forwardRef<FormHandles, Partial<FormProps>>(
  (props, ref: React.ForwardedRef<FormHandles>) => (
    // @ts-expect-error Resolve type bug
    <Unform ref={ref} {...props} />
  )
)

Form.displayName = 'Form'

export default Form
