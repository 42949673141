import React from 'react'
import { FiHelpCircle } from 'react-icons/fi'

import SortableButton from '../SortableButton'
import Tooltip from '../Tooltip'

import { Tr, Th, ThContentCell } from './styles'

const HeadRow: React.FC<HeadRow> = ({
  schema,
  sideBorder,
  focusColumn,
  total,
  activeSortKey,
  activeSortDirection,
  onSort
}) => (
  <Tr>
    {Object.keys(schema).map((key, index) => {
      const currentSchema = schema[key]

      return (
        // Object.keys(currentSchema).length && (
        <Th
          key={index}
          sideBorder={sideBorder}
          activeColumn={focusColumn === key}
          width={currentSchema.width}
          className={`${currentSchema.className || ''} ${
            currentSchema.classNameTH || ''
          }`}
        >
          <ThContentCell
            className="contentCell"
            as={currentSchema?.sortKey ? 'button' : 'div'}
            {...(currentSchema?.sortKey
              ? {
                  onClick: () =>
                    onSort?.({
                      key: currentSchema.sortKey,
                      sortInitialDirection:
                        currentSchema.sortInitialToggleDirection
                    })
                }
              : null)}
          >
            {(currentSchema.title || currentSchema.showTotal) && (
              <div className="mainInfo">
                <span className="text">
                  {currentSchema.title}

                  {currentSchema.showTotal && (
                    <>
                      {' '}
                      <small>
                        ({Number(total) > 100000 ? '+99.999' : total}{' '}
                        {total > 1 ? 'itens' : 'item'})
                      </small>
                    </>
                  )}
                </span>
              </div>
            )}

            {(!!currentSchema.sortKey || currentSchema.helpText) && (
              <aside>
                {currentSchema.helpText && (
                  <Tooltip
                    icon={<FiHelpCircle />}
                    text={currentSchema.helpText}
                    // positionProp="left-bottom"
                  />
                )}

                {!!currentSchema?.sortKey && (
                  <SortableButton
                    isActive={activeSortKey === currentSchema.sortKey}
                    direction={activeSortDirection}
                  />
                )}
              </aside>
            )}
          </ThContentCell>
        </Th>
        // )
      )
    })}
  </Tr>
)

export default HeadRow
