import React, { useRef, useEffect } from 'react'

import { useField } from '@unform/core'

import SelectSearchable from '../../SelectSearchable'

export default function ReactSelect({ name, ...rest }) {
  const selectRef = useRef(null)

  const { fieldName, defaultValue, registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: ref => {
        if (rest.isMulti) {
          return ref.state.selectValue
        }

        const selectedValue = ref.state?.selectValue?.[0]?.value

        if (!ref.state.value && !selectedValue) {
          return ''
        }

        return selectedValue || ref.state.value.value
      }
    })
  }, [fieldName, registerField, rest.isMulti, selectRef])

  return (
    <SelectSearchable
      defaultValue={defaultValue}
      selectRef={selectRef}
      {...rest}
    />
  )
}
