import { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { FiHelpCircle } from 'react-icons/fi'

import * as S from './styles'

const Tooltip = ({
  text,
  icon,

  noMargin = false
}: PropsTooltip) => {
  const [tooltipStyle, setTooltipStyle] = useState<React.CSSProperties>({})
  const [yPosition, setYPosition] = useState<TooltipYPosition>(null)
  const [xPosition, setXPosition] = useState<TooltipXPosition>(null)
  const [isVisible, setIsVisible] = useState(false)

  const targetRef = useRef(null)
  const tooltipRef = useRef(null)

  const calculateTooltipPosition = useCallback(() => {
    if (!targetRef.current || !tooltipRef.current) return

    const targetElement = targetRef.current
    const tooltipElement = tooltipRef.current

    const target = targetElement.getBoundingClientRect()
    const tooltip = tooltipElement.getBoundingClientRect()

    const windowWidth = window.innerWidth

    // Define available positions
    const fitsTop = target.top >= tooltip.height
    const fitsLeft = target.left >= tooltip.width
    const fitsRight = target.right + tooltip.width <= windowWidth

    // Handle position Y
    const positionY: TooltipYPosition = fitsTop ? 'top' : 'bottom'
    const isTop = positionY === 'top'

    // Handle position X
    const checkPositonX = () => {
      if (fitsLeft && fitsRight) return 'center'
      if (fitsLeft) return 'left'
      return 'right'
    }
    const positionX: TooltipXPosition = checkPositonX()

    const calcLeftPosition = () => {
      if (fitsLeft && fitsRight)
        return target.left + target.width / 2 - tooltip.width / 2
      if (fitsRight) return target.left - 4
      return target.left + target.width - tooltip.width + 4
    }

    // Define tooltip style
    const style: React.CSSProperties = {
      position: 'fixed',
      zIndex: 9999,
      top: `${isTop ? target.top - tooltip.height : target.bottom}px`,
      left: calcLeftPosition()
    }

    setYPosition(positionY)
    setXPosition(positionX)

    setTooltipStyle(style)
  }, [])

  useEffect(() => {
    if (!isVisible) return
    calculateTooltipPosition()
    window.addEventListener('resize', calculateTooltipPosition)

    return () => window.removeEventListener('resize', calculateTooltipPosition)
  }, [isVisible, calculateTooltipPosition])

  return (
    <>
      <div
        className={`Tooltip__Container d-inline-flex ${noMargin ? 'm-0' : 'ms-1'}`}
      >
        <div
          className="d-flex text-primary"
          onMouseEnter={() => {
            calculateTooltipPosition()
            setIsVisible(true)
          }}
          onMouseLeave={() => setIsVisible(false)}
          ref={targetRef}
        >
          {icon || <FiHelpCircle />}
        </div>
      </div>

      {isVisible &&
        createPortal(
          <S.TooltipContent
            ref={tooltipRef}
            className="Tooltip__Content"
            data-position-y={yPosition}
            data-position-x={xPosition}
            data-target-width={targetRef.current?.offsetWidth}
            style={tooltipStyle}
            onMouseEnter={() => {
              calculateTooltipPosition()
              setIsVisible(true)
            }}
            onMouseLeave={() => setIsVisible(false)}
          >
            <div className="text">{text}</div>
          </S.TooltipContent>,
          document.body
        )}
    </>
  )
}

export default Tooltip
