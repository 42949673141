import styled, { css } from 'styled-components'

export const ConfirmationContainer = styled.section`
  --color: var(--warning);

  &[data-template='warning'] {
    --color: var(--warning);
  }

  &[data-template='neutral'] {
    --color: var(--gray);
  }

  &[data-template='success'] {
    --color: var(--success);
  }

  &[data-template='danger'] {
    --color: var(--danger);
  }

  &[data-template='info'] {
    --color: var(--info);
  }

  &[data-template='primary'] {
    --color: var(--primary);
  }

  &[data-template='secondary'] {
    --color: var(--secondary);
  }

  ${({ theme }) => css`
    position: relative;
    display: flex;
    background-color: var(--color);
    border-left: 3px solid var(--color);
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacings.small};

    > * {
      position: relative;
      z-index: 1;
    }

    &:before {
      content: '';

      position: absolute;
      z-index: 0;

      top: 0;
      right: 0;

      width: calc(100% - 3px);
      height: 100%;

      border-radius: ${theme.border.radius.medium};
      background-color: rgba(255, 255, 255, 0.95);
    }
  `}
`

export const WarningIcon = styled.div`
  ${({ theme }) => css`
    flex-shrink: 0;

    > svg {
      font-size: ${theme.font.sizes.xlarge};
      color: var(--color);
    }
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.spacings.medium};
  `}
`

export const ConfirmationTitle = styled.strong`
  color: var(--color);
`
export const Description = styled.div`
  ${({ theme }) => css`
    margin-bottom: 0;
    color: var(--color);

    .highlight {
      font-weight: ${theme.font.bolder}
      text-decoration: underline;
      cursor: pointer;
    }
  `}
`
