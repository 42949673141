/* eslint-disable react/no-array-index-key */
import { useMemo, useState } from 'react'
import { FiX, FiAlertCircle, FiAlertTriangle } from 'react-icons/fi'

import { Button } from '~/components/Buttons'
import { TagsList } from '~/components/TagsList'
import { Textarea } from '~/components/unform'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

import { validateInput } from './helpers'

import * as S from './styles'

const TagInput = ({
  placeholder,
  tags,
  onCreate,
  onDelete,
  name,
  helperText = null,
  ...rest
}: TagInputProps) => {
  const [inputValue, setInputValue] = useState('')
  const [errors, setErrors] = useState([])
  const [warnings, setWarnings] = useState([])

  const { t } = useAPPTranslation()

  const handleCreate = () => {
    setErrors([])
    setWarnings([])

    const splitTerms = inputValue
      .toString()
      .replace(/[,;]/g, '\n')
      .replace(/\r\n/g, '\n')
      .split('\n')
      .filter(item => !!item.trim())
      .map(item => item.trim())

    const cleanArray = [...new Set(splitTerms)]

    const inputErrors = validateInput({
      inputValues: cleanArray,
      tags
    })

    if (inputErrors.length) {
      setErrors(inputErrors)
      return
    }

    const hasDuplicates = cleanArray.filter(item => tags.includes(item))

    if (hasDuplicates.length) {
      setWarnings([t('c:components.TagInput.alert.duplicateEntriesRemoved')])
    }

    onCreate([...new Set([...tags, ...cleanArray])])

    setInputValue('')
  }

  const handleInputChange = ({
    target: { value }
  }: {
    target: { value: string }
  }) => {
    setInputValue(value)
  }

  const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleCreate()
    }
  }

  const handleDelete = ({ id }: { id: string }) => {
    onDelete(Number(id))
  }

  const handleClearAll = () => {
    onCreate([])

    setInputValue('')
  }

  /**
   * FormattedTag
   */

  const formattedTags: Tag[] = useMemo(
    () => tags.map((item, index) => ({ id: String(index), label: item })),
    [tags]
  )

  return (
    <>
      <TagsList tags={formattedTags} onDelete={handleDelete} />

      {tags.length > 1 && (
        <Button
          size="xsmall"
          template="transparentDanger"
          text={t('c:components.TagInput.actions.removeAll')}
          onClick={handleClearAll}
          iconLeft={<FiX />}
          style={{ padding: 0 }}
        />
      )}

      <S.Container>
        <S.InputContainer>
          <Textarea
            name={name}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyPress}
            placeholder={placeholder}
            {...rest}
          />

          <Button
            text={t('c:actions.add')}
            onClick={handleCreate}
            disabled={!inputValue}
          />
        </S.InputContainer>

        {helperText && <small>{helperText}</small>}

        {errors?.map(error => (
          <small
            className="alert error"
            data-test="customer-tags-error"
            key={error}
          >
            <FiAlertCircle /> {error}
          </small>
        ))}

        {warnings?.map(item => (
          <small
            className="alert warning"
            data-test="customer-tags-warning"
            key={item}
          >
            <FiAlertTriangle /> {item}
          </small>
        ))}
      </S.Container>
    </>
  )
}

export default TagInput
