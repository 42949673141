import React, { useState, useCallback, useEffect, useRef } from 'react'
import { AlertCircle } from 'react-feather'

import { useField } from '@unform/core'
import PropTypes from 'prop-types'

import { Container, Error } from '../Input/styles'

/**
 *
 * @example
 *  <Input
 *    name="address_street"
 *    placeholder="Insira a rua"
 *    loading={loadingCep}
 *  />
 *
 * @param {string} name Recebe o name para o input
 * @param {function} onBlur Extendendo onBlur
 * @param {Object[]} options Array com as options do select
 * @param {string|number} options[].id ID usado como key do map e no id da option
 * @param {string} options[].value Recebe o value da option
 * @param {string} options[].text Recebe o texto da option
 * @param {boolean} options[].disabled Gerencia o estado da option
 *
 */

const Select = ({
  name,
  onBlur,
  onChange,
  options,
  className,
  placeholder,
  showErrorMsg,
  ...rest
}) => {
  const selectRef = useRef()

  const [isFocused, setIsFocused] = useState(false)
  const [isFilled, setIsFilled] = useState(false)

  const { fieldName, defaultValue, error, registerField, clearError } =
    useField(name)

  const handleInputFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleInputBlur = useCallback(() => {
    setIsFocused(false)

    setIsFilled(!!selectRef.current?.value)

    if (onBlur) {
      onBlur({ name: selectRef.current.name, value: selectRef.current.value })
    }

    if (selectRef.current?.value) {
      clearError()
    }
  }, [clearError, onBlur])

  const handleChange = useCallback(() => {
    if (onChange) {
      onChange(selectRef.current?.value)
    }

    if (selectRef.current?.value) {
      clearError()
    }
  }, [clearError, onChange])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])

  return (
    <Container isFocused={isFocused} isFilled={isFilled} isErrored={!!error}>
      {showErrorMsg && error && <div className="error">{error}</div>}

      <select
        name={fieldName}
        ref={selectRef}
        defaultValue={defaultValue || ''}
        className={`${className} ${error ? 'errorField' : ''}`}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        onChange={handleChange}
        {...rest}
      >
        <option disabled value="">
          {placeholder}
        </option>
        {options &&
          options.map(option => (
            <option
              key={option.id}
              value={option.value}
              id={`${name}_${option.id}`}
              disabled={option.disabled || false}
            >
              {option.text || option.label}
            </option>
          ))}
        ;
      </select>

      {error && (
        <Error title={error}>
          <AlertCircle />
        </Error>
      )}
    </Container>
  )
}

export { Select }

Select.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  showErrorMsg: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
}

Select.defaultProps = {
  className: '',
  placeholder: 'Escolha abaixo',
  options: null,
  showErrorMsg: false,
  onBlur: null,
  onChange: null
}
