import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

import moment from 'moment'

import AlertInfo from '~/components/AlertInfo'
import BadgeIsValid from '~/components/BadgeIsValid'
import { Button } from '~/components/Buttons'
import Panel from '~/components/Panel'
import {
  Checkbox,
  FormGroup,
  Input,
  InputMask,
  Label,
  Textarea
} from '~/components/unform'
import { getDurationInDays, formatHour } from '~/helpers'

import * as S from './styles'

/**
 * Este componente retornará um Panel conteúdo os campos necessários para cadastro de
 * nome, descrição(opcional) e um intervalo de tempo com data e horário de início e fim.
 */

const NameAndTimeInterval = ({
  formRef,
  titlePanel,
  labelTitle,
  placeholderTitle,
  labelDescription,
  placeholderDescription,
  hasDescription,
  labelInterval,
  onlyDates,
  endDateIsRequired = true,
  isEditMode,
  showValidBadgeOnSection = false,
  intervalAlertMessage = null,
  showEndDateOptional = false,
  containerElement = null,
  startDateReadOnly = false,
  autoFocus = 'name',
  initialData
}: NameAndTimeIntervalProps) => {
  const { t } = useTranslation()

  const [name, setName] = useState('')
  const [endDate, setEndDate] = useState('')
  const [startDate, setStartDate] = useState('')
  const [hasEndDate, setHasEndDate] = useState(
    !showEndDateOptional || !!initialData?.end_date
  )

  const handleChangeDate = useCallback(() => {
    const startDate = formRef?.current?.getFieldValue('start_date')
    const endDate = formRef?.current?.getFieldValue('end_date')

    const duration = getDurationInDays({ startDate, endDate })

    setEndDate(endDate)
    setStartDate(startDate)

    if (duration) formRef?.current?.setFieldValue('duration', duration)
  }, [formRef])

  const handleChange = useCallback(({ value }) => setName(value), [])

  const isValid = useMemo(() => {
    if (!showValidBadgeOnSection) {
      return false
    }

    const nameIsValid = name.length > 0
    const startDateIsValid = moment().isSameOrBefore(startDate, 'day')
    const endDateIsValid =
      !endDateIsRequired || moment(endDate).isSameOrAfter(startDate, 'day')

    return nameIsValid && startDateIsValid && endDateIsValid
  }, [endDate, endDateIsRequired, name, showValidBadgeOnSection, startDate])

  const iconLeft = useMemo(() => <BadgeIsValid isValid={isValid} />, [isValid])

  const handleEndDate = useCallback(() => {
    setHasEndDate(state => !state)
  }, [])

  // Podemos sobrescrever o elemento usado no container
  // Caso não queira usar o Panel padrão, podemos usar DIV ou qualquer outro
  const ContainerElement = useMemo(
    () => containerElement || Panel,
    [containerElement]
  )

  const containerPanelProps = useMemo(
    () => ({
      title: titlePanel,
      isCollapsible: true,
      iconLeft: showValidBadgeOnSection ? iconLeft : null,
      className: showValidBadgeOnSection && !isValid ? 'border-danger' : ''
    }),
    [iconLeft, isValid, showValidBadgeOnSection, titlePanel]
  )

  // Handle description
  const [showDescription, setShowDescription] = useState(false)

  return (
    <>
      <ContainerElement {...(!containerElement ? containerPanelProps : {})}>
        <S.Group>
          {labelInterval && <S.StrongTitle>{labelInterval}</S.StrongTitle>}

          <S.DateWrapper>
            <div className="start">
              <FormGroup>
                <Label htmlFor="start_date" isRequired>
                  {t('c:components.NameAndTimeInterval.startDate')}
                </Label>
                <Input
                  name="start_date"
                  autoFocus={autoFocus === 'start_date'}
                  type="date"
                  required
                  min={isEditMode ? null : moment().format('YYYY-MM-DD')}
                  max={endDate}
                  onChange={handleChangeDate}
                  disabled={startDateReadOnly}
                />
              </FormGroup>

              {!onlyDates && (
                <FormGroup>
                  <Label htmlFor="start_hour" isRequired>
                    {t('c:components.NameAndTimeInterval.startHour')}
                  </Label>
                  <InputMask
                    name="start_hour"
                    format={formatHour}
                    mask="_"
                    placeholder="HH:MM"
                    required
                    disabled={startDateReadOnly}
                  />
                </FormGroup>
              )}
            </div>

            {hasEndDate && (
              <>
                <div className="end">
                  <FormGroup>
                    <Label htmlFor="end_date" isRequired={endDateIsRequired}>
                      {t('c:components.NameAndTimeInterval.endDate')}
                    </Label>
                    <Input
                      name="end_date"
                      autoFocus={autoFocus === 'end_date'}
                      type="date"
                      required={endDateIsRequired}
                      min={
                        isEditMode ? moment().format('YYYY-MM-DD') : startDate
                      }
                      onChange={handleChangeDate}
                    />
                  </FormGroup>

                  {!onlyDates && (
                    <FormGroup>
                      <Label htmlFor="end_hour" isRequired={endDateIsRequired}>
                        {t('c:components.NameAndTimeInterval.endHour')}
                      </Label>
                      <InputMask
                        name="end_hour"
                        format={formatHour}
                        mask="_"
                        placeholder="HH:MM"
                        required={endDateIsRequired}
                      />
                    </FormGroup>
                  )}
                </div>

                <div className="duration">
                  <FormGroup>
                    <Label>
                      {t('c:components.NameAndTimeInterval.duration')}
                    </Label>
                    <Input name="duration" disabled readOnly />
                  </FormGroup>
                </div>
              </>
            )}

            {showEndDateOptional && (
              <S.WrapperCheckbox hasEndDate={hasEndDate}>
                <Checkbox
                  name="hasFinalDate"
                  label={t('c:components.NameAndTimeInterval.noEndDate')}
                  onClick={handleEndDate}
                  isCheckedInitialValue={!initialData?.end_date && true}
                  className="checkbox"
                />
              </S.WrapperCheckbox>
            )}
          </S.DateWrapper>

          {!!intervalAlertMessage &&
            intervalAlertMessage.map((message, index) => (
              <div className="alertMessage" key={index}>
                <AlertInfo template="info" text={message} />
              </div>
            ))}
        </S.Group>

        <S.Group>
          <Label
            text={labelTitle}
            helperText={t('c:components.NameAndTimeInterval.name.helperText')}
            htmlFor="name"
            isRequired
          />
          <Input
            placeholder={placeholderTitle}
            name="name"
            id="name"
            required
            autoFocus={autoFocus === 'name'}
            onChange={handleChange}
          />
        </S.Group>

        {hasDescription && (
          <S.Group>
            <Label
              htmlFor="description"
              style={showDescription ? {} : { marginBottom: 0 }}
              onClick={() => setShowDescription(prevState => !prevState)}
              role="button"
            >
              {showDescription ? <FiChevronDown /> : <FiChevronUp />}&nbsp;
              {labelDescription}
            </Label>
            {showDescription && (
              <Textarea
                name="description"
                id="description"
                placeholder={placeholderDescription}
                minHeight="auto"
                rows="2"
              />
            )}
          </S.Group>
        )}
      </ContainerElement>
    </>
  )
}

export default NameAndTimeInterval
