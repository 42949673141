import styled, { css } from 'styled-components'

export const Image = styled.div`
  ${({ theme, size }) => css`
    &[data-size='tiny'] {
      --size: 3rem;
    }

    &[data-size='small'] {
      --size: 4rem;
    }

    &[data-size='medium'] {
      --size: 6rem;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: var(--size);
    min-width: var(--size);
    max-width: var(--size);
    max-height: var(--size);
    margin-right: ${theme.spacings.xxxsmall};
    border: 0.25rem solid ${theme.colors.light};

    border-radius: ${theme.border.radius.large};

    background-color: ${theme.colors.light};

    .without-image {
      display: flex;
      justify-content: center;
      align-items: center;

      width: var(--size);
      height: var(--size);

      background-color: ${theme.colors.light};
      color: ${theme.colors.primaryLighten30};
    }

    img {
      mix-blend-mode: multiply;
      max-width: 0.93em;
      max-height: 0.93em;
      object-fit: cover;
    }
  `}
`

export const ImageModal = styled.img`
  width: 100%;
  height: auto;
  max-height: 90vh;
  margin-top: 1rem;
`
