import { FiAlertTriangle } from 'react-icons/fi'

import * as S from './styles'

export const AlertConfirmation: React.FC<AlertConfirmationProps> = ({
  description,
  template = 'danger',
  title,
  icon = null,
  children,
  ...rest
}) => (
  <S.ConfirmationContainer data-template={template} role="alert" {...rest}>
    <S.WarningIcon>{icon || <FiAlertTriangle />}</S.WarningIcon>

    <S.Content>
      <S.ConfirmationTitle>{title ? title : 'Alerta'}</S.ConfirmationTitle>
      <S.Description>{description}</S.Description>
      {children}
    </S.Content>
  </S.ConfirmationContainer>
)
