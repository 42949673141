import styled from 'styled-components'

export const TooltipContent = styled.div<TooltipContentProps>`
  --targetWidth: attr(data-target-width px);
  --color: var(--info);

  /* Safe area */
  padding: 0.5rem 0;
  width: max-content;

  .text {
    /* Layout */
    width: max-content;
    max-width: 320px;
    max-height: 100rem;
    padding: 0.5rem 0.75rem;

    /* Appearance */
    color: var(--white);
    background: var(--color);
    border-radius: 0.25rem;
    box-shadow:
      0px 12px 1rem -10px rgba(110, 113, 145, 0.8),
      0px 12px 1rem -10px rgba(110, 113, 145, 0.6);

    /* Typography */
    font-size: var(--font-size-xsm);
    line-height: 1.125;
    font-weight: normal;
    text-align: center;
  }

  /* Pseudo-element */
  &::after {
    content: '';
    position: absolute;
    border-width: 6px;
    border-style: solid;
    border-color: var(--color) transparent transparent transparent;

    transform: translateX(-50%);
  }

  &[data-position-y='bottom'] {
    &::after {
      transform: rotateX(180deg) translate(-50%, 0.25rem);
      top: 0;
    }
  }

  &[data-position-x='center'] {
    &::after {
      left: 50%;
    }
  }

  &[data-position-x='right'] {
    .text {
      text-align: left;
    }
    &::after {
      /* left: 0; */
      transform: translateX(calc(var(--targetWidth) * 0.5 + 4px));
    }
  }

  &[data-position-x='left'] {
    .text {
      text-align: right;
    }

    &::after {
      right: 0;
    }
  }
`
