import React, { useEffect, useRef, useState, useCallback } from 'react'
import { Icon } from 'react-feather'
import { Spinner } from 'reactstrap'

import { useField } from '@unform/core'
import PropTypes from 'prop-types'

import { Container, MessageError } from './styles'

/**
 *
 * @example
 *  <Textarea
 *    name="address_street"
 *    placeholder="Insira a rua"
 *    loading={loadingCep}
 *    icon={FiSearch}
 *  />
 *
 * @param {string} name Recebe o name para o input
 * @param {ReactElement | string} icon Ícone que será exbido a esquerda do input.
 * @param {boolean} loading Exibe um loader ao lado do input
 * @param {function} onBlur Extendendo onBlur
 *
 */

type TextAreaProps = {
  name: string
  icon?: Icon
  htmlIcon?: React.ReactNode
  loading?: boolean
  onBlur?: (value?: unknown) => void
  onError?: (value?: unknown) => void
  onChange?: (event: OnChangeTextAreaData) => void
  minHeight?: string | number | null
  showLength?: boolean
  maxLength?: number
} & Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'>

const Textarea = ({
  name,
  icon: Icon,
  loading,
  onBlur,
  htmlIcon,
  onError,
  minHeight = null,
  onChange = null,
  showLength = false,
  ...rest
}: TextAreaProps) => {
  const textAreaRef = useRef(null)

  const [isFocused, setIsFocused] = useState(false)
  const [isFilled, setIsFilled] = useState(false)
  const [valueInput, setValueInput] = useState('')

  const { fieldName, defaultValue, error, registerField, clearError } =
    useField(name)

  const handleInputFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleInputBlur = useCallback(() => {
    setIsFocused(false)

    setIsFilled(!!textAreaRef.current?.value)

    if (onBlur) {
      onBlur({
        name: textAreaRef.current.name,
        value: textAreaRef.current.value
      })
    }

    if (textAreaRef.current?.value) {
      clearError()
    }
  }, [clearError, onBlur])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textAreaRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])

  const defaultValueIsNull = defaultValue === null

  const defaultValueIsUndefined = typeof defaultValue === 'undefined'

  useEffect(() => {
    if (!defaultValueIsNull && !defaultValueIsUndefined) {
      setValueInput(defaultValue)
      setIsFilled(true)
    }
  }, [defaultValue, defaultValueIsNull, defaultValueIsUndefined])

  useEffect(() => {
    onError?.(error)
  }, [error, onError])

  const inputChangedHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value, name } = e.target

    onChange && onChange({ value, name, target: e.target })

    setValueInput(value)
  }

  return (
    <>
      <Container
        isFocused={isFocused}
        isFilled={isFilled}
        isErrored={!!error}
        minHeightTextarea={minHeight}
      >
        {Icon && <Icon />}

        {htmlIcon && <div className="htmlIcon">{htmlIcon}</div>}

        <textarea
          onFocus={handleInputFocus}
          onChange={inputChangedHandler}
          name={fieldName}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          id={name}
          {...rest}
          ref={textAreaRef}
        />

        {loading && <Spinner type="grow" size="sm" color="secondary" />}
      </Container>

      {showLength && rest.maxLength && (
        <footer className="d-flex justify-content-end mt-1">
          <span className="fs-6 text-neutral-low-light">
            {valueInput ? valueInput.length : 0}/{rest.maxLength}
          </span>
        </footer>
      )}

      {error && <MessageError>{error}</MessageError>}
    </>
  )
}

export default Textarea

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  htmlIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  loading: PropTypes.bool,
  onBlur: PropTypes.func,
  onError: PropTypes.func,
  minHeight: PropTypes.string
}

Textarea.defaultProps = {
  icon: null,
  htmlIcon: null,
  loading: false,
  onBlur: null,
  onError: null,
  minHeight: null
}
