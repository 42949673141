import styled, { css } from 'styled-components'

export const BadgeNotifications = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1rem;
    margin-left: 0.25rem;
    padding: 0.25rem;
    min-width: 1rem;
    max-width: 3rem;

    border-radius: 2rem;
    line-height: 1rem;
    font-size: ${theme.font.sizes.xxsmall};

    background: ${theme.colors.danger};
    color: ${theme.colors.white};
  `}
`
